.Cards {
    height: auto;
    width: 80%;
    margin: 5rem auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 3rem;
    grid-row-gap: 5rem;
    grid-auto-flow: row;
}

@media only screen and (max-width: 639px) {
    .Cards {
        grid-gap: 2rem;
    }
}

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.LaSquareSpin,
.LaSquareSpin > div {
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.LaSquareSpin {
    display: block;
    font-size: 0;
    color: #fff;
}

.LaSquareSpin > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}

.LaSquareSpin {
    width: 32px;
    height: 32px;
}

.LaSquareSpin > div {
    width: 100%;
    height: 100%;
    border-radius: 0;
    -webkit-animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9)
        infinite;
    -moz-animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9)
        infinite;
    -o-animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
    animation: square-spin 3s 0s cubic-bezier(0.09, 0.57, 0.49, 0.9) infinite;
}

.LaSquareSpin.LaSm {
    width: 16px;
    height: 16px;
}

.LaSquareSpin.La2x {
    width: 64px;
    height: 64px;
}

.LaSquareSpin.La3x {
    width: 96px;
    height: 96px;
}

/*
 * Animation
 */
@-webkit-keyframes square-spin {
    0% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0);
    }

    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0);
    }

    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg);
    }

    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg);
    }

    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(360deg);
        transform: perspective(100px) rotateX(0) rotateY(360deg);
    }
}

@-moz-keyframes square-spin {
    0% {
        -moz-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0);
    }

    25% {
        -moz-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0);
    }

    50% {
        -moz-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg);
    }

    75% {
        -moz-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg);
    }

    100% {
        -moz-transform: perspective(100px) rotateX(0) rotateY(360deg);
        transform: perspective(100px) rotateX(0) rotateY(360deg);
    }
}

@-o-keyframes square-spin {
    0% {
        transform: perspective(100px) rotateX(0) rotateY(0);
    }

    25% {
        transform: perspective(100px) rotateX(180deg) rotateY(0);
    }

    50% {
        transform: perspective(100px) rotateX(180deg) rotateY(180deg);
    }

    75% {
        transform: perspective(100px) rotateX(0) rotateY(180deg);
    }

    100% {
        transform: perspective(100px) rotateX(0) rotateY(360deg);
    }
}

@keyframes square-spin {
    0% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(0);
        -moz-transform: perspective(100px) rotateX(0) rotateY(0);
        transform: perspective(100px) rotateX(0) rotateY(0);
    }

    25% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(0);
        -moz-transform: perspective(100px) rotateX(180deg) rotateY(0);
        transform: perspective(100px) rotateX(180deg) rotateY(0);
    }

    50% {
        -webkit-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        -moz-transform: perspective(100px) rotateX(180deg) rotateY(180deg);
        transform: perspective(100px) rotateX(180deg) rotateY(180deg);
    }

    75% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(180deg);
        -moz-transform: perspective(100px) rotateX(0) rotateY(180deg);
        transform: perspective(100px) rotateX(0) rotateY(180deg);
    }

    100% {
        -webkit-transform: perspective(100px) rotateX(0) rotateY(360deg);
        -moz-transform: perspective(100px) rotateX(0) rotateY(360deg);
        transform: perspective(100px) rotateX(0) rotateY(360deg);
    }
}
