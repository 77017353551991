.Hero {
    flex-grow: 1;
    background: url(../../assets/Foody.jpg);
    width: 100%;
    background-size: cover;
    background-position: center;
    background-color: #000000bd;
    background-blend-mode: hue;
    color: #fff;
}

.Hero h1 {
    font-size: 5rem;
    font-weight: 700;
}

.Hero h2 {
    font-size: 4rem;
    font-weight: 500;
}
