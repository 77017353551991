/* html {
    overflow: scroll;
    overflow-x: hidden;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
} */

.Home {
    display: flex;
    flex-flow: column;
    height: 100vh;
}
